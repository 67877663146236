import React from 'react'
import SEOTools from '../../components/SEOTools'
import { graphql } from 'gatsby'
import { Button, Col, Container, Jumbotron, Row } from 'react-bootstrap'
import { GatsbyImage, getImage, getSrc, StaticImage } from 'gatsby-plugin-image'
import useSmoothScrollTo from '../../hooks/useSmoothScrollTo'
import {
    faAd,
    faBullhorn,
    faDatabase,
    faQrcode,
    faSearchDollar,
    faShoppingBasket,
    faSmile,
    faStoreAlt,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ContactForm from '../../components/Forms/ContactForm'
import Layout from '../../components/Layout'

/**
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
const SiruQRPage = ({ data }) => {
    const heroBg = getImage(data.heroBg)
    const checkOutBg = getImage(data.checkOutBg)

    const handleScrollToFeatures = useSmoothScrollTo('features', { offset: -45 })
    const handleScrollToContact = useSmoothScrollTo('contact', { offset: -45 })

    return (
        <Layout>
            <SEOTools
                title='Siru QR'
                description='QR code platform for your customers.'
                image={getSrc(data.heroBg.childImageSharp)}
            />

            <section className='darker-background-overlay position-relative'>
                <div className='text-white mb-0 d-flex align-items-center full-screen-jumbo'>
                    <Container>
                        <Row>
                            <Col lg={{ span: 6, offset: 1 }} md='8'>
                                <h1 className='name mb-4 text-uppercase'>Siru QR</h1>
                                <p className='message mb-5 pb-3'>
                                    Get More Out Of <strong>Your QR Codes</strong>
                                </p>
                                <Button
                                    variant='success'
                                    size='lg'
                                    className='px-5 rounded-0'
                                    onClick={handleScrollToContact}>
                                    Talk to sales
                                </Button>
                                <Button
                                    variant='link'
                                    size='lg'
                                    className='px-5 py-3 rounded-0'
                                    onClick={handleScrollToFeatures}>
                                    Read more
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                    <GatsbyImage
                        className='z-index-n1 position-absolute w-100 h-100'
                        alt=''
                        image={heroBg}
                        role='presentation'
                        placeholder='blurred'
                        loading='eager'
                    />
                </div>
            </section>

            <section id='features'>
                <Jumbotron fluid>
                    <Container>
                        <Row>
                            <Col lg={{ span: 10, offset: 1 }}>
                                <h2>Features</h2>
                                <Row className='mb-3'>
                                    <Col md='1' xs='2' className='pt-1 pl-4'>
                                        <FontAwesomeIcon icon={faSmile} size='lg' />
                                    </Col>
                                    <Col md='5' xs='10' className='pl-0'>
                                        <p className='spaced-light mb-2'>Qr code for different uses</p>
                                        <p>Memorable customer experiences</p>
                                    </Col>

                                    <Col md='1' xs='2' className='pt-1 pl-4'>
                                        <FontAwesomeIcon icon={faDatabase} size='lg' />
                                    </Col>
                                    <Col md='5' xs='10' className='pl-0'>
                                        <p className='spaced-light mb-2'>Merchant portal</p>
                                        <p>Customer flows control in shopping centers</p>
                                    </Col>
                                </Row>

                                <Row className='mb-3'>
                                    <Col md='1' xs='2' className='pt-1 pl-4'>
                                        <FontAwesomeIcon icon={faQrcode} size='lg' />
                                    </Col>
                                    <Col md='5' xs='10' className='pl-0'>
                                        <p className='spaced-light mb-2'>QR remote sale</p>
                                        <p>QR code campaign and be flexible for any use case.</p>
                                    </Col>

                                    <Col md='1' xs='2' className='pt-1 pl-4'>
                                        <FontAwesomeIcon icon={faAd} size='lg' />
                                    </Col>
                                    <Col md='5' xs='10' className='pl-0'>
                                        <p className='spaced-light mb-2'>Effective advertising</p>
                                        <p>Effective store-specific advertising for chain stores</p>
                                    </Col>
                                </Row>

                                <Row className='mb-3'>
                                    <Col md='1' xs='2' className='pt-1 pl-4'>
                                        <FontAwesomeIcon icon={faShoppingBasket} size='lg' />
                                    </Col>
                                    <Col md='5' xs='10' className='pl-0'>
                                        <p className='spaced-light mb-2'>Easy access to new markets</p>
                                        <p>Supports sales by also bringing customers to the brick-and-mortar stores</p>
                                    </Col>

                                    <Col md='1' xs='2' className='pt-1 pl-4'>
                                        <FontAwesomeIcon icon={faSearchDollar} size='lg' />
                                    </Col>
                                    <Col md='5' xs='10' className='pl-0'>
                                        <p className='spaced-light mb-2'>Target your customers</p>
                                        <p>
                                            Effective advertising budget management, precise targeting to different
                                            customer segments
                                        </p>
                                    </Col>
                                </Row>

                                <Row className='mb-3'>
                                    <Col md='1' xs='2' className='pt-1 pl-4'>
                                        <FontAwesomeIcon icon={faStoreAlt} size='lg' />
                                    </Col>
                                    <Col md='5' xs='10' className='pl-0'>
                                        <p className='spaced-light mb-2'>Custom solution</p>
                                        <p>Easy "buy and pick up" concept</p>
                                    </Col>

                                    <Col md='1' xs='2' className='pt-1 pl-4'>
                                        <FontAwesomeIcon icon={faBullhorn} size='lg' />
                                    </Col>
                                    <Col md='5' xs='10' className='pl-0'>
                                        <p className='spaced-light mb-2'>Reach bigger markets</p>
                                        <p>A lot of different opportunities for the active marketer</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Jumbotron>
            </section>

            <section className='darker-background-overlay position-relative'>
                <div className='d-flex text-white text-center mb-0 pb-0'>
                    <Container>
                        <Row className='mt-4 mb-0 pb-0 d-flex justify-content-center'>
                            <Col md='10'>
                                <h2>QR codes for every use case</h2>
                                <p className='spaced-light mb-5'>
                                    SiruQR offers a wide range of different content types for your QR code. Get the
                                    right type for your QR code campaign and be flexible for any use case.
                                </p>
                                <StaticImage
                                    src='../../images/products/siruqr/qr_code.png'
                                    alt='logo'
                                    placeholder='blurred'
                                    width={350}
                                />
                            </Col>
                        </Row>
                    </Container>
                    <GatsbyImage
                        className='z-index-n1 position-absolute w-100 h-100'
                        alt=''
                        image={checkOutBg}
                        role='presentation'
                        placeholder='blurred'
                        loading='lazy'
                    />
                </div>
            </section>

            <section id='contact'>
                <Container className='py-5'>
                    <Row className='mt-4 mb-0 pb-0 d-flex justify-content-center'>
                        <Col md='10'>
                            <h2>Contact our sales team</h2>
                            <ContactForm name='sales-contact' />
                        </Col>
                    </Row>
                </Container>
            </section>
        </Layout>
    )
}

export const query = graphql`
    query {
        heroBg: file(relativePath: { eq: "products/siruqr/siruqr_hero.jpg" }) {
            childImageSharp {
                gatsbyImageData(width: 3840, placeholder: BLURRED, quality: 80, transformOptions: { cropFocus: CENTER })
            }
        }
        checkOutBg: file(relativePath: { eq: "products/siruqr/sirumobile_product_qr.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 3840
                    height: 500
                    placeholder: BLURRED
                    quality: 80
                    transformOptions: { cropFocus: CENTER, fit: OUTSIDE }
                )
            }
        }
    }
`

export default SiruQRPage
